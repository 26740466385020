html,
body,
#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  transition: background-color 0.2s;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
  }
}
